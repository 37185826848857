import React from 'react';

import Layout from '../../components/Layout';

export default function Rettungsdienst() {
	return (
		<Layout title="Rettungsdienst">
			<h2>Rettungsdienst</h2>
			<p>
				Mitglieder der Bereitschaft Bad Reichenhall unterstützen das hauptamtliche Rettungsdienst-Personal Tag
				und Nacht.
			</p>
			<p>
				Unter der Telefonnummer <span className="brk-important">112</span> sind Rettungsdienst, Notarzt,
				Bergwacht und Wasserwacht 24 Stunden am Tag für Sie erreichbar!
			</p>
		</Layout>
	);
}
